<template>
  <div class="help">
    <div class="top-area-wrap">
      <TopBar
        @menuMouseover="menuMouseover"
        @menuMouseleave="menuMouseleave"
        ref="topCom"
        :isBlack="true"
        :userInfo="userInfo"
      />

      <ProjectMenu
        class="project-menu-area"
        v-show="projectMenuShow"
        :hiddenFun="hiddenProjectMenu"
        :stopFun="stopFun"
        :menuList="menuList"
      />
    </div>
    <div class="help-middle-area">
      <div class="banner">
        <div class="font">
          <div class="helpLeft"></div>
          帮助中心
          <div class="helpRight"></div>
        </div>
      </div>
      <div class="main-content">
        <div class="type-area">
          <ul>
            <li @click="documentLink">
              <div class="imagebox">
                <img src="../assets/helpImg/documentCenter.png" />
              </div>
              <h3>文档中心</h3>
              <div></div>
              <p>快速定位产品，便携浏览内容</p>
            </li>
            <li @click="videoLink">
              <div class="imagebox">
                <img src="../assets/helpImg/videoRegion.png" />
              </div>
              <h3>视频专区</h3>
              <div></div>
              <p>跟着视频演示更快玩转产品</p>
            </li>
            <li @click="practicesClick">
              <div class="imagebox">
                <el-tooltip effect="dark" content="正在建设中" placement="top">
                  <img src="../assets/helpImg/bestPractices.png" />
                </el-tooltip>
              </div>
              <h3>最佳实践</h3>
              <div></div>
              <p>历史最佳实践，提供解决方案新思路</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="homepage">
        <div class="homecenter">
          <div class="tabs" v-if="sceneList.length">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                v-for="item in sceneList"
                :label="item.sceneName"
                :name="item.sceneName"
                :key="item.sceneId"
              >
                <ul>
                  <li
                    style="margin-left: 26px"
                    v-for="next in item.nextList"
                    :key="next.sceneId"
                  >
                    <template>
                      <div class="squire">
                        <span class="squire-circle"></span>
                        <p class="squire-name">{{ next.sceneName }}</p>
                      </div>

                      <ul
                        class="dataSource"
                        :class="{ notChild: next.children.length === 0 }"
                      >
                        <li
                          @click="toDocDetail(child)"
                          v-for="child in next.children"
                          :key="child.docId"
                          v-show="child.dataStatus === 1"
                        >
                          <span class="circle"></span>
                          <span class="datasource-name">{{
                            child.docName
                          }}</span>
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="empty" v-else>
            <p>更多内容敬请期待~</p>
          </div>
        </div>
      </div>
    </div>

    <div class="help-bottom-area"><Bottom /></div>
  </div>
</template>

<script>
import { findAll2 } from "@/server/api";
import TopBar from "@/components/TopBar.vue";
import ProjectMenu from "@/components/ProjectMenu.vue";
import Bottom from "./component/Bottom.vue";
export default {
  data() {
    return {
      projectMenuShow: false,
      input: "",
      activeName: "first",
      sceneList: [],
      menuList: [],
      userInfo: {},
    };
  },
  components: {
    TopBar,
    ProjectMenu,
    Bottom,
  },
  async mounted() {
    const result = await findAll2();
    this.menuList = result.data.rspData;
  },
  methods: {
    practicesClick() {},
    toDocDetail(data) {
      window.open(`${location.origin}${data.docUrl}`);
    },
    stopFun() {
      clearTimeout(this.timer);
    },
    menuMouseover(code) {
      if (code === "produce") {
        this.projectMenuShow = true;
      }
    },
    menuMouseleave(code) {
      if (code === "produce") {
        this.timer = setTimeout(() => {
          this.projectMenuShow = false;
          this.$refs.topCom.selectedProduce(false);
        }, 200);
      }
    },
    hiddenProjectMenu() {
      this.projectMenuShow = false;
      this.$refs.topCom.selectedProduce(false);
    },
    //点击tab
    handleClick(tab, event) {
    },
    //点击文档
    documentLink() {
      this.$router
        .push({
          path: "/document",
        })
        .catch(() => {});
    },
    videoLink() {
      this.$router
        .push({
          path: "/video",
        })
        .catch(() => {});
    },
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.$http.get("/api/support/help/support/queryAll").then((res) => {
      this.sceneList = res.data.rspData;
      this.activeName = res.data.rspData[0].sceneName;
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-tabs__item {
    height: 44px;
    line-height: 44px;
    font-size: 20px;
  }
}
a {
  text-decoration: none;
  color: #393f49;
}
.help {
  position: relative;
  display: flex;
  flex-direction: column;

  .top-area-wrap {
    position: relative;
  }
}
.help-middle-area {
  // margin-top: 60px;
  flex: 1;
  overflow-y: auto;
}

.banner {
  background-image: url(../assets/helpImg/helpBanner.png);
  background-size: cover;
  height: 350px;
  width: 100%;
  padding-top: 95px;
  box-sizing: border-box;

  .font {
    font-size: 48px;
    width: 450px;
    height: 160px;
    margin: 0 auto;
    text-align: center;
    line-height: 160px;
    background: rgba(255, 255, 255, 0.64);
    position: relative;
    .helpLeft {
      width: 24px;
      height: 38px;
      border-top: 3px solid #000;
      border-left: 3px solid #000;
      position: absolute;
      left: 64px;
      top: 44px;
      font-size: 48px;
    }
    .helpRight {
      width: 24px;
      height: 38px;
      border-bottom: 3px solid #000;
      border-right: 3px solid #000;
      position: absolute;
      right: 64px;
      bottom: 44px;
    }
    // opacity: 64;
  }
}

.main-content {
  height: 200px;
  display: none;
  .type-area {
    width: 1200px;
    height: 200px;
    margin: -200px auto 0;
    ul {
      // display: flex;
      // justify-content: space-between;
      list-style: none;
      cursor: pointer;
      li {
        float: left;
        margin: 0 10px;
        box-sizing: border-box;
        width: 373px;
        height: 400px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(238, 240, 246, 1);
        box-shadow: 0 2px 10px 0 rgba(238, 240, 246, 1);
        text-align: center;
        z-index: 9;
        .imagebox {
          width: 105px;
          height: 105px;
          background: #fff;
          margin: 77px auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        h3 {
          margin-top: -40px;
          margin-bottom: 12px;
          font-size: 24px;
        }
        p {
          font-size: 16px;
          color: #858a94;
        }
        div {
          margin: 0 auto 34px;
          width: 48px;
          height: 2px;
          background: rgba(57, 63, 73, 1);
        }
      }
    }
  }
}
.homepage {
  margin-top: 45px;
  .homecenter {
    width: 1200px;
    margin: 0 auto;
    .empty {
      text-align: center;
      height: 185px;
      line-height: 185px;
      font-size: 24px;
      color: #393f49;
    }
    .tabs {
      padding-bottom: 64px;
      ul {
        border-left: 1px solid #dde0e8;
        li {
          list-style-type: none;
          margin-top: 40px;
          list-style-position: inside;
          font-size: 18px;
          .squire {
            height: 26px;
            display: flex;
            margin-left: -26px;
            align-items: center;
            border-left: 3px solid rgba(65, 70, 78, 1);
            padding-left: 24px;
            cursor: pointer;
            span.squire-circle {
              box-sizing: border-box;
              width: 9px;
              height: 9px;
              border-radius: 50%;
              border: 2px solid rgba(57, 63, 73, 1);
              display: inline-block;
              vertical-align: middle;
            }
            .squire-name {
              margin-left: 4px;
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              display: inline-block;
              vertical-align: middle;
            }
            &.current {
              color: rgba(201, 4, 42, 1);
              border-left: 3px solid rgba(201, 4, 42, 1);
              span {
                border: 2px solid rgba(201, 4, 42, 1);
              }
            }
            &:hover {
              color: rgba(201, 4, 42, 1);
              border-left: 3px solid rgba(201, 4, 42, 1);
              span {
                border: 2px solid rgba(201, 4, 42, 1);
              }
            }
          }
          ul.dataSource {
            width: 1173px;
            margin-top: 16px;
            border: 1px solid rgba(214, 217, 227, 1);
            padding: 16px;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            &.notChild {
              height: 46px;
            }
            li {
              cursor: pointer;
              width: 269px;
              height: 38px;
              line-height: 38px;
              margin: 8px;
              box-sizing: border-box;
              background-color: #f8f9fb;
              font-size: 14px;
              display: flex;
              align-items: center;
              span.circle {
                margin-left: 16px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: rgba(57, 63, 73, 1);
                margin-right: 10px;
                display: inline-block;
                vertical-align: middle;
              }
              span.datasource-name {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
              }
              &:hover {
                span.circle {
                  background: rgba(201, 4, 42, 1);
                }
                span.datasource-name {
                  color: rgba(201, 4, 42, 1);
                }
              }
            }
            li:nth-of-type(4n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
.el-tabs__item {
  font-size: 18px;
  font-weight: normal;
  color: rgba(57, 63, 73, 1);
}
.el-tabs__active-bar {
  background-color: rgba(201, 4, 42, 1);
}
.el-tabs__item.is-active {
  font-weight: 600;
  color: rgba(201, 4, 42, 1);
}
.el-tabs__item:hover {
  font-weight: 600;
  color: rgba(201, 4, 42, 1);
}

li:visited {
  color: red;
}
</style>
